<template>
  <div class="container-fluid">
    <Header header="Detail" title="Notification" :state="state"/>

    <div class="row">
      <!-- Column -->
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="list-group" v-if="headerNotif.length">
              <a
                exact
                v-for="notif in headerNotif"
                :class="['list-group-item list-group-item-action flex-column align-items-start', notif.read_at == null ? 'active': '']"
                v-bind:key="notif.id"
                @click="updateNotification(notif.id)"
                href="javascript:void(0)"
              >
                <div
                  class="d-flex w-100 justify-content-between"
                >
                  <h5 :class="['mb-1', notif.status ? 'text-white':'']">{{ notif.title }}</h5>
                  <small>{{ '('.concat(notif.time_human).concat(') ').concat(formatDate(notif.time)) }}</small>
                </div>
                <p class="mb-1">{{notif.description}}</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- Column -->
    </div>

    <!-- END DASHBOARD -->
  </div>
</template>
<script>
import axios from "axios";
import Header from "@/components/pageTitle/index";

export default {
  components: {
    Header
  },
  data() {
    return {
      state: {
        isEdit: false,
        isAdd: false,
        isDetail: false
      },
      headerNotif:[]
    };
  },
  mounted() {
    axios.get('notifications-all').then(res=>{
      this.headerNotif = res.data.data.map(value=>{
        return {
          time:value.created_at,
          title:value.title,
          description:value.message,
          read_at:value.read_at,
          time_human:value.time_human,
          id:value.id,
          status:value.status?true:false
        };
      });
    }).catch(()=>{
      // console.log(err);
    })
  },
  methods: {
    async updateNotification(id) {
      try {
        await axios.put(`/notifications/${id}`);
        this.$router.push({
          name:'notification detail-id',
          params:{
            id:`${id}`
          }
        });
      } catch { ()=> {
        // console.log(e);
        }
      }
    }
  }
};
</script>
